import * as React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { Box } from "@mui/material";

const PreviewCompatibleImage = ({ imageInfo }) => {
  const imageStyle = { 
    // borderRadius: "5px" 
  };

  const { alt = "", childImageSharp, image } = imageInfo;

  if (!!image && !!image.childImageSharp) {
    return (
      
      <Box
    sx={{
      // border: "2px solid blue",
      // width: "120px",
      // height: "120px",
      // overflow: "hidden"

    }}> 
    
    <GatsbyImage
        image={image.childImageSharp.gatsbyImageData}
        style={imageStyle}
        alt={alt}
        
        // objectFit={"cover"}
        // objectPosition={"50% 50%"}
        // aspectRatio={1/1}
      />
      </Box>
    );
  } else if (!!childImageSharp) {
    return (

      <Box
    sx={{
      // border: "2px solid red"
    }}> 

      <GatsbyImage
        image={childImageSharp.gatsbyImageData}
        style={imageStyle}
        alt={alt}
        // aspectRatio={1/1}

      />
      </Box>
    );
    // for Netlify CMS 
  } else if (image) {
    return <Box
    sx={{
      // border: "2px solid yellow"
    }}> 
      
      <img style={{imageStyle}} src={image} alt={alt} />
      </Box>;
  } else {
    return null
  }
};

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object,
  }).isRequired,
};

export default PreviewCompatibleImage;
