import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material'


const BlogRollTemplate = (props) => {

  const { edges: posts } = props.data.allMarkdownRemark;

  return (
    <Grid container spacing={2}>
      {posts &&
        posts.map(({ node: post }) => (
          <Grid item key={post.id} xs={6}>
            <Link
              to={post.frontmatter.slug}
            >
              <Paper elevation={3}>
                <Stack direction="row"

                  alignItems="center"
                  sx={{
                    // border: "1px solid black"
                  }}>


                  <Box
                    sx={{
                      flexShrink: 0
                    }}>


                    {post?.frontmatter?.featuredimage && (



                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.featuredimage,
                          alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                          width:
                            post.frontmatter.featuredimage.childImageSharp
                              .gatsbyImageData.width,
                          height:
                            post.frontmatter.featuredimage.childImageSharp
                              .gatsbyImageData.height,
                        }}
                      />


                    )}

                  </Box>

                  <Stack
                    sx={{
                      px: 2,
                      py: 1
                    }}>


                    <Typography variant="caption">
                      {post.frontmatter.overline}
                    </Typography>

                    <Typography variant="h5">

                      {post.frontmatter.title}
                    </Typography>







                    {/* <Link
                    // className="button"
                    to={post.frontmatter.slug}>
                    <Button>
                      czytaj
                    </Button>
                  </Link> */}
                  </Stack>

                </Stack>

              </Paper>
            </Link>

          </Grid>
        ))}
    </Grid>
  )
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}


export default function BlogRoll() {
  return (
    <StaticQuery
      query={graphql`
        query BlogRollQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { published: { eq: true } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  slug
                  overline
                  templateKey
                  date(formatString: "MMMM DD, YYYY")
                  featuredpost
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(
layout: CONSTRAINED
 aspectRatio: 1
 width: 120
  transformOptions: {
 cropFocus: CENTER

 }    
                        quality: 100
                      )

                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <BlogRollTemplate data={data} count={count} />}
    />
  );
}

// https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby/src/query/__tests__/fixtures/query-compiler-schema.graphql
// filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
// gravity: southeast
